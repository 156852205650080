import { ReactNode } from "react";

export type AlertType = "error" | "success" | "info" | "warning";

export type NewAlert = {
  text: string | ReactNode;
  type: AlertType;
  timeout?: number;
};

export type Alert = {
  id: string;
  text: string | ReactNode;
  type: AlertType;
};

export enum AlertDialogType {
  AccountRequired = "ACCOUNT_REQUIRED",
  Default = "DEFAULT",
  DeleteRoom = "DELETE_ROOM",
  DeleteComment = "DELETE_COMMENT",
  DeleteGeneration = "DELETE_GENERATION",
  PasswordResetSuccess = "PASSWORD_RESET_SUCCESS",
  QuotaLimitReached = "QUOTA_LIMIT_REACHED",
  SubscriptionRequired = "SUBSCRIPTION_REQUIRED",
  ToggleSafeSearch = "TOGGLE_SAFE_SEARCH",
  Unsubscribe = "UNSUBSCRIBE",
  UpdateAvailable = "UPDATE_AVAILABLE",
  UpdateSelectedModel = "UPDATE_SELECTED_MODEL",
}

export type AlertDialogContent = {
  title: string;
  type: AlertDialogType;
  description?: string;
  data?: any;
  nextButtonText?: string;
  prevButtonText?: string;
  singleOption?: boolean;
};

export type AlertDialog = AlertDialogContent & {
  id: string;
};
